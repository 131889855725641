<template>
  <v-card flat class="px-5 pb-5">
    <v-card-title class="pb-8">Получение маршрутки клиента</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4" class="text-color">
          <v-text-field
            outlined
            label="Номер билета"
            v-model="query.ticketNumber"
          />
        </v-col>
        <v-col cols="12" md="4" class="text-color">
          <v-select
            outlined
            label="Статус"
            v-model="query.status"
            :items="statuses"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row class="px-4">
        <v-col cols="6" md="3">
          <v-btn
            block
            dark
            color="orange"
            :disabled="isDownload"
            style="width: 100%; height: 3.3rem"
            @click="clear"
            >Очистить форму
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="6" md="3">
          <v-btn
            block
            dark
            :loading="isDownload"
            color="green"
            :disabled="isDisabled"
            style="width: 100%; height: 3.3rem"
            @click="download"
            >Получить
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { downloadFile } from "@/mixins/utils.js";

export default {
  name: "Wwss",
  data() {
    return {
      query: {
        ticketNumber: "",
        status: null,
      },
      isDownload: false,
      statuses: [
        { text: "Неважно", value: null },
        { text: "Ожидает отправки", value: 1 },
        { text: "В обработке СМС-центра", value: 3 },
        { text: "Доставлено", value: 4 },
        { text: "Не доставлено", value: 5 },
        { text: "Чек не найден", value: 2 },
        { text: "Неккоректные данные", value: 0 },
        { text: "Неизвестно", value: 6 },
      ],
    };
  },
  methods: {
    async download() {
      this.isDownload = true;
      try {
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Admin.Receipt.ShowClient,
          this.query
        );
        if (!resp.data.succeeded) {
          this.$store.state.snackbar = {
            show: true,
            message: "Ничего не найдено",
            color: "orange",
            timeout: 3000,
            contentClass: "text-center text-h5",
          };
          return;
        }
        const arr = resp.data.resultData.map(
          (e) =>
            new Uint8Array(
              atob(e)
                .split("")
                .map(function (c) {
                  return c.charCodeAt(0);
                })
            )
        );
        console.info("arr", arr);
        arr.forEach((element) => {
          downloadFile(element, { name: `${this.query.ticketNumber}.pdf` });
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.isDownload = false;
      }
    },
    clear() {
      this.value = "";
    },
  },
  computed: {
    isDisabled() {
      return !this.query.ticketNumber;
    },
  },
};
</script>

<style scoped></style>
